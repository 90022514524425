import React from "react"
import { graphql, Link } from "gatsby"
import CardLink from "../components/card"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(limit: $limit, skip: $skip, filter: {fields: {pageType: {eq: "blog"}}}) {
      edges {
        node {
          excerpt(pruneLength: 143)
          fields {
            slug
          }
          frontmatter {
            title,
            featuredImage {
                childImageSharp {
                  fluid(maxWidth: 530, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  }   
`


const NewsPage = ({ data, pageContext }) => {
    const { currentPage, numPages, blogPath } = pageContext;
    const hasPreviousPage = currentPage - 1 > 0;
    const hasNextPage = currentPage + 1 <= numPages;
    const previousPageUrl = `${blogPath}` + ( hasPreviousPage && (currentPage-1 !== 1) ?  `/${currentPage-1}` : '');
    const nextPageUrl = `${blogPath}/` + (hasNextPage ? `/${currentPage+1}` : `${currentPage}`);
    return (
        <Layout>
            <SEO title="Blog News"/>
            <section className="section">
                <div className="title has-text-centered">News</div>
                <div className="container">
                    <div className="columns is-multiline">
                    {
                        data.allMarkdownRemark.edges.map(({node: page}) => (
                            <div key={ `${page.fields.slug}-div`} className="column is-4" >
                                <CardLink 
                                    key={page.fields.slug}
                                    title={page.frontmatter.title}
                                    excerpt={page.excerpt}
                                    subtitle={page.frontmatter.subtitle} 
                                    url={ `${blogPath}/${page.fields.slug}`} 
                                    featuredImage={page.frontmatter.featuredImage}
                                />
                            </div>  
                    ))}
                    </div>

                </div>
                

                <nav className="pagination is-centered" role="navigation" aria-label="pagination">
                    <Link className={'pagination-previous' + (!hasPreviousPage ? ' is-hidden' : '') } 
                                to={previousPageUrl}>Previous</Link>
                    <Link className={'pagination-next' + (!hasNextPage ? ' is-hidden' : '')} 
                        to={nextPageUrl}>Next</Link>
                    <ul className="pagination-list">
                        {Array.from({ length: numPages }, (_, i) => (
                            <li key={`pnli${i + 1}`}>
                                <Link className={'pagination-link' + (currentPage === i + 1 ? ' is-current' : '') } 
                                        key={`pna${i + 1}`} to={`${blogPath}${i === 0 ? "" : i + 1}`}>
                                    {i + 1}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </section>
        </Layout>
)}

export default NewsPage
